import React from "react";
import { Row, Col } from "react-flexbox-grid";
import Masonry from "react-masonry-css";
//Scss
import "./portfolio.scss";
//Assets
import Arrow from "../../assets/portfolio/arrow.svg";

// Animals
import AllTheDogs from "../../assets/portfolio/media/animals/all_three_dogs.png";
import ActualWolf from "../../assets/portfolio/media/animals/an_actual_wolf.png";
import CharlieBeach from "../../assets/portfolio/media/animals/charlie_on_the_beach.png";
import CharliePennyBeach from "../../assets/portfolio/media/animals/penny_and_charlie_looking_out.png";
import Gyp from "../../assets/portfolio/media/animals/gyp.png";
import CutePenny from "../../assets/portfolio/media/animals/penny_looking_cute.png";
import PennyWithBall from "../../assets/portfolio/media/animals/penny_with_a_ball.png";

// Cars
import Bmw4Series from "../../assets/portfolio/media/cars/bmw_4_convertible.png";
import BmwX3 from "../../assets/portfolio/media/cars/bmw_x3.png";
import BmwX4 from "../../assets/portfolio/media/cars/bmw_x4.png";
import FerrariGtr from "../../assets/portfolio/media/cars/ferrari_and_gtr.png";
import LegoLambo from "../../assets/portfolio/media/cars/lego_lambo.png";
import MercEClass from "../../assets/portfolio/media/cars/merc_e_class.png";
import Mgb from "../../assets/portfolio/media/cars/mgb.png";
import MgbSuspension from "../../assets/portfolio/media/cars/mgb_rebuilt_suspension.png";
import MustangConvertible from "../../assets/portfolio/media/cars/mustang_convertible.png";
import Mustang from "../../assets/portfolio/media/cars/red_mustang.png";
import VwGolf from "../../assets/portfolio/media/cars/vw_golfs.png";
import Winning from "../../assets/portfolio/media/cars/winning.png";

// Food
import Beyond from "../../assets/portfolio/media/food/beyond.png";
import McDsBigVegan from "../../assets/portfolio/media/food/big_vegan.png";
import Brooklyn from "../../assets/portfolio/media/food/brooklyn_smorgasburg_vegan_food.png";
import FlatironRoom from "../../assets/portfolio/media/food/flatiron_room_bourbon_whiskey_nyc.png";
import Nix from "../../assets/portfolio/media/food/nix_nyc.png";
import Nuggets from "../../assets/portfolio/media/food/nuggets.png";
import Purezza from "../../assets/portfolio/media/food/purezza_pizza.png";
import Biscuits from "../../assets/portfolio/media/food/vegan_biscuits.png";
import Burgers from "../../assets/portfolio/media/food/vegan_burgers.png";
import Pancakes from "../../assets/portfolio/media/food/vegan_pancakes.png";
import SmokedSalmon from "../../assets/portfolio/media/food/vegan_smoked_salmon.png";
import Wagamama from "../../assets/portfolio/media/food/wagamama_avant_garden.png";

// People
import Me from "../../assets/portfolio/media/people/me.png";
import MeMaz from "../../assets/portfolio/media/people/me_and_maz.png";
import Kite from "../../assets/portfolio/media/people/me_and_my_kite.png";
import Woods from "../../assets/portfolio/media/people/me_in_the_woods.png";
import MeMazPenny from "../../assets/portfolio/media/people/me_maz_and_penny.png";
import MeJeffDad from "../../assets/portfolio/media/people/me_jeff_dad.png";
import Mum from "../../assets/portfolio/media/people/mum.png";
import Dad from "../../assets/portfolio/media/people/dad.png";
import MeJeffMumDad from "../../assets/portfolio/media/people/me_jeff_mum_dad.png";

// Travel
import AbrahamLincoln from "../../assets/portfolio/media/travel/abraham_lincoln_washington_dc.png";
import Bos from "../../assets/portfolio/media/travel/bos_bar_nyc.png";
import BrooklynBridge from "../../assets/portfolio/media/travel/brooklyn_bridge.png";
import CentralPark from "../../assets/portfolio/media/travel/central_park_nyc.png";
import LondonOlympics from "../../assets/portfolio/media/travel/london _olympics_2012.png";
import Marrakech from "../../assets/portfolio/media/travel/marrakech_souks.png";
import MazRoadTrip from "../../assets/portfolio/media/travel/me_and_maz_road_trip_usa.png";
import MeRoadTrip from "../../assets/portfolio/media/travel/me_usa_road_trip.png";
import EaglesNest from "../../assets/portfolio/media/travel/the_eagles_nest.png";
import UnionSquare from "../../assets/portfolio/media/travel/union_aquare_market_manhattan.png";

//Components
import Button from "../ui-components/button/button";
import Title from "../ui-components/title/title";
import ProjectBox from "../ui-components/projectBox/projectBox";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // PORTFOLIO PROJECTS
      projects: [
        {
          id: "1",
          preview: AllTheDogs,
          title: "ALL THREE SPANIELS",
          tag: "animals",
        },
        {
          id: "2",
          preview: ActualWolf,
          title: "AN ACTUAL WOLF",
          tag: "animals",
        },
        {
          id: "3",
          preview: CharlieBeach,
          title: "CHARLIE",
          tag: "animals",
        },
        {
          id: "4",
          preview: Gyp,
          title: "GYP",
          tag: "animals",
        },
        {
          id: "5",
          preview: CutePenny,
          title: "PENNY",
          tag: "animals",
        },
        {
          id: "6",
          preview: PennyWithBall,
          title: "PENNY PLAYING",
          tag: "animals",
        },
        {
          id: "7",
          preview: Bmw4Series,
          title: "ROAD TRIP BMW 4 SERIES CONVERTIBLE",
          tag: "cars",
        },
        {
          id: "8",
          preview: BmwX3,
          title: "OUR BMW X3",
          tag: "cars",
        },
        {
          id: "9",
          preview: BmwX4,
          title: "ROAD TRIP BMW X4",
          tag: "cars",
        },
        {
          id: "10",
          preview: FerrariGtr,
          title: "FERRARI & NISSAN GTR TEST DRIVE",
          tag: "cars",
        },
        {
          id: "11",
          preview: LegoLambo,
          title: "LEGO LAMBO",
          tag: "cars",
        },
        {
          id: "12",
          preview: MercEClass,
          title: "ROAD TRIP MERCEDES E CLASS CONVERTIBLE",
          tag: "cars",
        },
        {
          id: "13",
          preview: Mgb,
          title: "MY MGB",
          tag: "cars",
        },
        {
          id: "14",
          preview: MgbSuspension,
          title: "REBUILT MGB SUSPENSION",
          tag: "cars",
        },
        {
          id: "15",
          preview: MustangConvertible,
          title: "ROAD TRIP MUSTANG CONVERTIBLE",
          tag: "cars",
        },
        {
          id: "16",
          preview: Mustang,
          title: "ROAD TRIP MUSTANG",
          tag: "cars",
        },
        {
          id: "17",
          preview: VwGolf,
          title: "OUR OLD GOLFS",
          tag: "cars",
        },
        {
          id: "18",
          preview: Winning,
          title: "GO CART RACING",
          tag: "cars",
        },
        {
          id: "19",
          preview: Beyond,
          title: "LOTS OF BEYOND",
          tag: "food",
        },
        {
          id: "20",
          preview: McDsBigVegan,
          title: "MC DONALDS BIG VEGAN",
          tag: "food",
        },
        {
          id: "21",
          preview: Brooklyn,
          title: "BROOKLYN SMORGASBURG",
          tag: "food",
        },
        {
          id: "22",
          preview: FlatironRoom,
          title: "FLATIRON ROOM WHISKY",
          tag: "food",
        },
        {
          id: "23",
          preview: Nix,
          title: "NIX NYC",
          tag: "food",
        },
        {
          id: "24",
          preview: Nuggets,
          title: "VEGAN NUGGETS",
          tag: "food",
        },
        {
          id: "25",
          preview: Purezza,
          title: "PUREZZA PIZZA LONDON",
          tag: "food",
        },
        {
          id: "26",
          preview: Biscuits,
          title: "VEGAN BISCUITS",
          tag: "food",
        },
        {
          id: "27",
          preview: Burgers,
          title: "BEYOND OR IMPOSSIBLE",
          tag: "food",
        },
        {
          id: "28",
          preview: Pancakes,
          title: "VEGAN PANCAKES",
          tag: "food",
        },
        {
          id: "29",
          preview: SmokedSalmon,
          title: "VEGAN SMOKED SALMON",
          tag: "food",
        },
        {
          id: "30",
          preview: Wagamama,
          title: "WAGAMAMA AVANT GARDEN",
          tag: "food",
        },
        {
          id: "31",
          preview: Me,
          title: "ME",
          tag: "people",
        },
        {
          id: "32",
          preview: MeMaz,
          title: "ME AND MAZ",
          tag: "people",
        },
        {
          id: "33",
          preview: Kite,
          title: "ME WITH A KITE",
          tag: "people",
        },
        {
          id: "34",
          preview: Woods,
          title: "WOODS",
          tag: "people",
        },
        {
          id: "35",
          preview: MeMazPenny,
          title: "US THREE",
          tag: "people",
        },
        {
          id: "36",
          preview: AbrahamLincoln,
          title: "ABRAHAM LINCOLN, WASHINGTON DC",
          tag: "travel",
        },
        {
          id: "37",
          preview: Bos,
          title: "BO'S BAR NYC",
          tag: "travel",
        },
        {
          id: "38",
          preview: BrooklynBridge,
          title: "BROOKLYN BRIDGE",
          tag: "travel",
        },
        {
          id: "39",
          preview: CentralPark,
          title: "CENTRAL PARK",
          tag: "travel",
        },
        {
          id: "40",
          preview: LondonOlympics,
          title: "LONDON OLYMPICS 2012",
          tag: "travel",
        },
        {
          id: "41",
          preview: Marrakech,
          title: "MARRAKECH SOUKS",
          tag: "travel",
        },
        {
          id: "42",
          preview: MazRoadTrip,
          title: "ROAD TRIP WITH MAZ",
          tag: "travel",
        },
        {
          id: "43",
          preview: MeRoadTrip,
          title: "ROAD TRIP",
          tag: "travel",
        },
        {
          id: "44",
          preview: EaglesNest,
          title: "EAGLES NEST",
          tag: "travel",
        },
        {
          id: "45",
          preview: UnionSquare,
          title: "UNION SQUARE",
          tag: "travel",
        },
        {
          id: "46",
          preview: Dad,
          title: "DAD",
          tag: "people",
        },
        {
          id: "47",
          preview: Mum,
          title: "MUM",
          tag: "people",
        },
        {
          id: "48",
          preview: MeJeffDad,
          title: "DAD, JEFF & ME IN OSLO",
          tag: "people",
        },
        {
          id: "49",
          preview: MeJeffMumDad,
          title: "MUM, DAD, JEFF & ME",
          tag: "people",
        },
        {
          id: "50",
          preview: CharliePennyBeach,
          title: "PENNY & CHARLIE",
          tag: "animals",
        },
      ],
      // PORTFOLIO GALLERY WILL LOAD THIS AFTER FUNCTION "filterGallery" FINISH FILTERING
      filterResult: null,
      pickedFilter: "all",
      filterMenuActive: false,
      pickedFilterDropdown: "NEWEST"
    };
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  // FIRST LOAD
  componentDidMount() {
    this.filterGallery("all");
  }

  //FILTER PORTFOLIO FUNCTION
  filterGallery = (target) => {
    let projectsArr = [...this.shuffle(this.state.projects)];
    let result;

    if (target !== "all") {
      result = projectsArr.filter((project) => project.tag === target);
    } else {
      result = projectsArr;
    }

    this.setState({ filterResult: result, pickedFilter: target, pickedFilterDropdown: "NEWEST" });
  };

  // FILTER DROP DOWN HOVER MENU FUNCTION
  filterMenuHover = (event) => {
    if(event) {
      this.setState({ filterMenuActive: true });
    }else {
      this.setState({ filterMenuActive: false });
    }
  }

  // FILTER DROP DOWN HANDLER
  filterDropDownHandler = (filter) => {
    this.setState({ pickedFilterDropdown: filter, filterMenuActive: false });

    let projectsArr = [...this.state.filterResult];
    let result;

    if (filter === "NEWEST") {
      result = projectsArr.sort((a, b) => (a.id > b.id ? 1 : -1));
    }else if (filter === "OLDEST") {
      result = projectsArr.sort((a, b) => (a.id > b.id ? 1 : -1)).reverse();
    }

    this.setState({ filterResult: result});
  }

  // RENDER
  render() {
    // PORTFOLIO GALLERY RENDER
    let projectsRender = null;
    if (this.state.filterResult) {
      projectsRender = this.state.filterResult.map((project) => (
        <ProjectBox preview={project.preview} key={project.id} title={project.title} tag={project.tag} />
      ));
    }
    // PORTFOLIO GALLERY BREAKPOINTS
    const portfolioBreakpoints = {
      default: 3,
      1100: 3,
      700: 2,
      500: 1,
    };
    // PORTFOLIO FILTER DROPDOWN MENY RENDER
    let filterDroppDown = null;
    if(this.state.filterMenuActive) {
      filterDroppDown = (
        <div className="portfolio__filter-menu shadow">
          <p className="font12" onClick={() => this.filterDropDownHandler("NEWEST")}>
            NEWEST
          </p>
          <p className="font12" onClick={() => this.filterDropDownHandler("OLDEST")}>
            OLDEST
          </p>
        </div>
      );
    }

    return (
      <div id="portfolio">
        <div className="wrapper">
          <Title title="ME, IN PICTURES." />
          <Row>
            <Col xs={12} sm={12} md={8} lg={9}>
              <div className="portfolio__nav">
                <ul>
                  <li className={this.state.pickedFilter === "all" ? "portfolio__nav-active font12" : "font12"} onClick={() => this.filterGallery("all")}>
                    ALL
                  </li>
                  <li
                    className={this.state.pickedFilter === "travel" ? "portfolio__nav-active font12" : "font12"}
                    onClick={() => this.filterGallery("travel")}
                  >
                    TRAVEL
                  </li>
                  <li
                    className={this.state.pickedFilter === "food" ? "portfolio__nav-active font12" : "font12"}
                    onClick={() => this.filterGallery("food")}
                  >
                    FOOD
                  </li>
                  <li
                      className={this.state.pickedFilter === "animals" ? "portfolio__nav-active font12" : "font12"}
                      onClick={() => this.filterGallery("animals")}
                  >
                    ANIMALS
                  </li>
                  <li
                      className={this.state.pickedFilter === "cars" ? "portfolio__nav-active font12" : "font12"}
                      onClick={() => this.filterGallery("cars")}
                  >
                    CARS
                  </li>
                  <li
                      className={this.state.pickedFilter === "people" ? "portfolio__nav-active font12" : "font12"}
                      onClick={() => this.filterGallery("people")}
                  >
                    PEOPLE
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={3}>
              <div className="portfolio__filter" onMouseEnter={() => this.filterMenuHover(true)} onMouseLeave={() => this.filterMenuHover(false)}>
                <p className="font12">{this.state.pickedFilterDropdown} FIRST</p>
                <img src={Arrow} alt="arrow" />
                {filterDroppDown}
              </div>
            </Col>
          </Row>
          <Masonry breakpointCols={portfolioBreakpoints} className="my-masonry-grid" columnClassName="mint__gallery">
            {projectsRender}
          </Masonry>
          <Row className="flex-center padding40">
            <Button label="HAVE WORK FOR ME?" target={"contact"} />
          </Row>
        </div>
      </div>
    );
  }
}

export default Portfolio;
