import React from "react";
import Swiper from "react-id-swiper";
import ReactSoundcloud from 'react-soundcloud-embedded';
// SCSS
import "./music.scss";
import "swiper/css/swiper.css";
// Components
import Title from "../ui-components/title/title";

class Music extends React.Component {
  state = {
    // LIST ARRAY OF BLOG STORIES
    tracks: [
      {
        id: 2,
        url: "https://soundcloud.com/plasticaftertaste/something-else"
      },
      {
        id: 2,
        url: "https://soundcloud.com/plasticaftertaste/in-your-head"
      },
      {
        id: 3,
        url: "https://soundcloud.com/plasticaftertaste/out-there-remastered"
      }
    ],
  };

  render() {
    // BLOG STORIES RENDER
    let tracksRender = null;
    if (this.state.tracks) {
      tracksRender = this.state.tracks.map((track) => (
        <div key={track.id}>
          <ReactSoundcloud url={track.url} autoPlay={false} visual={false}/>
        </div>
      ));
    }
    // OPTIONS FOR BLOG SLIDER
    const params = {
      grabCursor: true,
      slidesPerView: 2,
      spaceBetween: 10,
      loop: true
    };

    return (
      <div className="music" id="music">
        <div className="wrapper">
          <Title title="MY MUSIC." />
          <p className="font12">
            I've been a fan of electronic music for as long as I can remember, and I've recently tried my hand at producing my own. Below are my tracks, my artist name is Plasticaftertaste.
          </p>
          <div className="padding30">
            <Swiper {...params}>{tracksRender}</Swiper>
          </div>
        </div>
      </div>
    );
  }
}

export default Music;
