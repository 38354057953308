import React from "react";
// SCSS
import "./teamInfo.scss";

const about = ({title, text}) => (
  <div className="team__info flex-center-top">
    <div>
      <h4 className="font20 weight800">{title}</h4>
      <p className="font12 weight500">{text}</p>
    </div>
  </div>
);

export default about;
