import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="ABOUT ME." />
      <p className="font12">
        I'm a technology enthusiast, software engineer, fanatic of anything with an engine,
        supporter of animal rights and vegan. I also enjoy listening to, and occasionally
        producing, electronic music. Scroll down to find out a bit more about me.
      </p>
      <Row>
        <Col md={12} lg={3}>
          <TeamInfo title="Travel" text="I enjoy visiting new places and seeing as many wonderful sights as possible. I've had the opportunity spend a number of months in New York City and it remains one of my favourite cities." />
        </Col>
        <Col md={12} lg={3}>
          <TeamInfo title="Photography" text="When and where possible I like to capture landscapes and cityscape's, as well as trying to get creative sometimes. Some examples are below." />
        </Col>
        <Col md={12} lg={3}>
          <TeamInfo title="Cars" text="I'm particularly fanatical about cars, but I'll give anything with wheels and an engine a go. I'll take any opportunity for a road trip, my favourite to date has been from NYC to Burlington, VT, in a Mustang." />
        </Col>
        <Col md={12} lg={3}>
          <TeamInfo title="Animals" text="I've been lucky enough to have grown up with dogs, cats, rabbits and other animals. All animals are great, but dogs are the best." />
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
